<template>
  <div class="honor_container">
    <h1>我们的荣誉资质</h1>
    <h2>每一次进步，凝聚于每一份服务</h2>
    <div class="platform">
      <div class="_item">
        <div class="_txt">国家<br />高新技术企业</div>
      </div>
      <div class="_item">
        <div class="_txt">成都市中小企业<br />数字化转型<br />专业服务商</div>
      </div>
    </div>
    <div class="platform">
      <div class="_item">
        <div class="_txt">2022年度<br />企业数字化转型<br />最佳服务商</div>
      </div>
      <div class="_item">
        <div class="_txt">阿里云<br />精英级服务商</div>
      </div>
    </div>
    <div class="platform">
      <div class="_item">
        <div class="_txt">阿里云<br />阿里云百城计划<br />城市服务商</div>
      </div>
      <div class="_item">
        <div class="_txt">阿里云<br />大数据与AI生态<br />合作伙伴</div>
      </div>
    </div>
    <div class="platform">
      <div class="_item">
        <div class="_txt">阿里云<br />优秀创新<br />合作伙伴</div>
      </div>
      <div class="_item">
        <div class="_txt">钉钉全国十大<br />新锐服务商</div>
      </div>
    </div>
    <div class="platform">
      <div class="_item">
        <div class="_txt">专属钉钉<br />区域核心代理</div>
      </div>
      <div class="_item">
        <div class="_txt">TEAMBITION<br />授权服务中心</div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.honor {
  &_container {
    position: relative;
    padding: 35px 10px 40px;
    background: #1a1a1a url(../../assets/about/about_honor.jpg) no-repeat top center;
    background-size: 100% auto;
    position: relative;
    overflow: hidden;
    color: white;

    >h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    >h2 {
      font-size: 15px;
      text-align: center;
      font-weight: 400;
      padding: 15px 0;
    }

    .platform {
      padding: 15px 23px 20px;
      background: url(../../assets/about/platform.png) no-repeat center bottom;
      background-size: 100% auto;
      display: flex;
      justify-content: space-between;
    }

    ._item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 15px;
      color: #383838;
      font-weight: bold;
      padding: 0 10px;
      position: relative;

      &::before {
        content: '';
        position: relative;
        background: url(../../assets/about/honor_back.png) no-repeat center center;
        background-size: cover;
        padding-bottom: 62.04%;
        width: 100%;
      }

      ._txt {
        position: absolute;
      }
    }
  }
}
</style>