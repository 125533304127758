<template>
  <div class="partners_module">
    <h2>合作伙伴 · PARTNERS </h2>
    <img src="/static/img/partners_20241029.jpg" />
  </div>
</template>
<style lang="less" scoped>
.partners_module {
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #383838;
    line-height: 1;
    padding: 20px;
    text-align: center;
  }

  img {
    display: block;
    width: 100%;
  }
}
</style>