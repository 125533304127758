
import Creative from '../components/about/Creative.vue'
import Honor from '../components/about/Honor.vue'
import Future from '../components/about/Future.vue'
import Team from '../components/about/Team.vue'
import Contact from '../components/about/Contact.vue'
import Partner from '../components/about/Partner.vue'
export default {
  components:{
    Creative,
    Future,
    Team,
    Contact,
    Honor,
    Partner
  }
}
